<template>
    <section class="post">
        <div class="container">
            <h1>A keresett oldal nem található!</h1>
        </div>
    </section>
</template>

<script>
export default {
    name: "NotFound"
};
</script>

<style scoped></style>
